<template>
	<quill-editor
		:class="[!error || 'err', !$vuetify.dark || 'white--text' ]"
		ref="myQuillEditor"
		v-model="text"
		:options="{
			placeholder: 'Escribe aquí el mensaje...',
			modules: {
				toolbar: toolbarOptions,
			},
		}"
	/>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";
export default {
	components: {
		quillEditor,
	},
	props: [
		"value",
		"error"
	],
	data() {
		return {
			toolbarOptions: [
				["bold", "italic", "underline", "strike"], // toggled buttons
				["blockquote", "code-block"],

				[{ header: 1 }, { header: 2 }], // custom button values
				[{ list: "ordered" }, { list: "bullet" }],
				[{ script: "sub" }, { script: "super" }], // superscript/subscript
				[{ indent: "-1" }, { indent: "+1" }], // outdent/indent

				[{ size: ["small", false, "large", "huge"] }], // custom dropdown
				[{ header: [1, 2, 3, 4, 5, 6, false] }],

				[{ color: [] }, { background: [] }], // dropdown with defaults from theme
				[{ font: [] }],
				[{ align: [] }],

				["clean"], // remove formatting button
			],
		};
	},
	computed: {
		text: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit("input", val);
			},
		},
	},
};
</script>

<style>
.ql-toolbar.ql-snow, .err .ql-container.ql-snow {
	border-width: 2px !important;
}
.ql-toolbar.ql-snow {
	border-radius: 5px 5px 0 0 !important;
}
.theme--dark .ql-toolbar.ql-snow .ql-fill {
	/* fill: white !important; */
}
.theme--dark .ql-editor.ql-blank::before {
	color: white !important;
}
.ql-container.ql-snow {
	border-radius: 0 0 5px 5px !important;
}
.err .ql-toolbar.ql-snow, .err .ql-container.ql-snow {
	border-color: var(--v-error-base) !important;
	animation: shake .3s;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(1px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-2px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(2px, 0, 0);
  }
}
</style>